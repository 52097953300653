import './public-path';
import './site.css';

const mobileMenuToggle = document.getElementById('mobileMenuToggle');
const menu = document.getElementById('mainMenu');
let menuOpen = false;

mobileMenuToggle.addEventListener('click', () => {
  if (menuOpen) {
    menu.classList.add('hidden');
    menuOpen = false;
  } else {
    menu.classList.remove('hidden');
    menuOpen = true;
  }
});

window.BAABCO.wheelOfGrowth = () => {
  import('./wheel-of-growth');
};
